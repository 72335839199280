
































import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import AddSupplierCategory from '@/components/goods/add-supplier-category.vue'
import { apiSupplierCategoryDel, apiSupplierCategoryLists } from '@/api/goods'
import { RequestPaging } from '@/utils/util'
@Component({
  components: {
    LsDialog,
    LsPagination,
    AddSupplierCategory
  }
})
export default class SupplierCategory extends Vue {
  $refs!: { addSupplierCategory: any }
  loading = false
  pager = new RequestPaging()
  form: any = {
    name: '',
    sort: ''
  }

  lists = []
  count = 0

  handleAdd() {
    this.form = {
      name: '',
      sort: ''
    }
    this.$refs.addSupplierCategory.openDialog()
  }

  handleEdit({ id, name, sort }: any) {
    this.form = {
      id,
      name,
      sort
    }
    this.$refs.addSupplierCategory.openDialog()
  }

  handleDelete(id: number) {
    apiSupplierCategoryDel(id).then(() => {
      this.getList()
    })
  }

  getList() {
    this.pager
      .request({
        callback: apiSupplierCategoryLists
      })
      .then((res: any) => {
        this.lists = res?.lists
        this.count = res?.count
      })
  }

  created() {
    this.getList()
  }
}
